<template>
  <div class="callback">
    <h5>正在跳转。。。</h5>
  </div>
</template>
<script>
import { getUrlParam, cacheLoginUser, homePage } from "@/utils/tools";
import { loginByCode } from "@/utils/api";
export default {
  name: "Callback",
  beforeMount: function() {
    var code = getUrlParam("code");
    if (code) {
      let state = getUrlParam("state");
      let params = {
        code: code,
        state: state,
      };
      // 根据钉钉返回code登录
      loginByCode(params)
        .then((res) => {
          let ur = res.data;
          // 缓存用户信息
          cacheLoginUser(ur);
        })
        .catch((err) => {
          // 返回首页
          homePage()
        });
    } else {
      homePage();
    }
  },
};
</script>
